import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DatatreeComponent } from '../datatree/datatree.component';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [DatatreeComponent, AppComponent]
})
export class MenuComponent implements OnInit, AfterViewInit {
  constructor(private router: Router) {
    window.addEventListener('resize', () => {
      this.menuShow();
    });
  }

  menuVisibility: boolean = true;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.menuShow();
  }
  
  menuShow() {
    let w = window.innerWidth;
    if (w <= 600) {
      if (this.menuVisibility)
        this.menuVisibility = false;
    }
    else if (!this.menuVisibility)
      this.menuVisibility = true;
  }
  menuItemClick(bname: string) {
    if(bname=='tryit'){
      window.open("https://tryit.webschool.ge");
      return;
    }
    let w = window.innerWidth;
    if (w <= 600 && this.menuVisibility) {
      this.menuVisibility = false;
    }
    this.clearActive();
    this.activateMenu(bname);
  }
  headerClick() {
    this.clearActive();
  }
  clearActive() {
    let menuItems = document.getElementsByClassName("menuitem");
    Array.prototype.forEach.call(menuItems, function (el) {
      (<HTMLElement>el).classList.remove('active');
    });
  }
  activateMenu(bname: string) {
    var el = document.getElementById(bname);
    if (el != null) {
      el.classList.add('active');
    }
  }
}
