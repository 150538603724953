import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Theme } from '../../sdk/dataservice';
import { Router } from '@angular/router';

@Component({
  selector: 'app-datatree',
  templateUrl: './datatree.component.html',
  styleUrls: ['./datatree.component.css']
})
export class DatatreeComponent implements OnInit {
  @Input() data: Theme[];
  @Output() itemclick: EventEmitter<any> = new EventEmitter();

  @Input() fontSize = 14;
  iconSize = this.fontSize;

  @Input() iconColor = 'var(--color1)';
  @Input() fontColor = 'var(--color2)';
  @Input() hoverColor = 'var(--color3)';
  // @Input() selectedColor = 'var(--darkGreen)';
  @Input() fontFamily = 'bpg_arial, sans-serif';

  @Input() setData() {
    // this.loadData();
    console.log('Refreshed');
  }
  @Input() reInit(data: Theme[], bid) {
    this.loadData(data, bid);
  }
  constructor(private router: Router) { }

  ngOnInit() {
    // this.loadData();
  }
  @Input() BookName;
  @Input() loadData(data: Theme[] = null, id: number = null) {
    var tree = document.getElementById('datatree');
    if (tree) {
      tree.innerHTML = "";
    }
    else
      return;

    if (data)
      this.data = data;
    if (!this.data || this.data.length == 0)
      return;

    var themes0 = this.data.filter(el => el.parentid == null);
    themes0.forEach(el => {
      var rw = this.loadRow(el);
      tree.appendChild(rw);
    });
    this.selectNode(id);
  }

  selectNode(id: number) {
    var elms = document.getElementsByClassName('activeItem');
    if (elms && elms.length > 0)
      Array.prototype.forEach.call(elms, function (ell) {
        (<HTMLElement>ell).classList.remove('activeItem');
        (<HTMLElement>ell).style.backgroundColor = 'transparent';
      });

    if (!this.data || this.data.length == 0)
      return;
    if (!id)
      id = this.data[0].id;

    let elm = document.getElementById("id_theme_" + id.toString());
    if (!elm) {
      id = this.data[0].id;
      elm = document.getElementById("id_theme_" + id.toString());
    }
    if (!elm)
      return;

    elm.classList.add('activeItem');
    // elm.style.backgroundColor = this.selectedColor;
    // elm.style.color = 'white';
    let th = new Theme();
    th.id = id;
    th.name = elm.innerText;
    this.itemclick.emit(th);
    this.expandElid(id);
    this.router.navigate([this.BookName, id]);
  }

  loadRow(el: Theme): HTMLElement {
    var rw = document.createElement('div');
    rw.style.display = "table-row";
    rw.style.height = this.iconSize * 1.8 + "px";

    var col1 = document.createElement('div');
    col1.style.display = "table-cell";
    col1.style.cursor = "pointer";
    col1.style.width = this.iconSize * 1.5 + 'px';
    var themes0 = this.data.filter(t => t.parentid == el.id);
    var img = <HTMLElement>document.createElement('i');
    img.id = "id_theme_" + el.id.toString() + "_img";
    img.style.color = this.iconColor;
    img.style.fontSize = this.iconSize + "px";
    img.style.paddingLeft = this.iconSize / 3 + 'px';
    img.style.paddingRight = this.iconSize / 3 + 'px';
    if (themes0.length > 0) {
      img.innerHTML = '<i class="fa fa-caret-right"></i>';
      img.setAttribute('data-toggle', 'collapse');
      img.setAttribute('data-target', '#id_theme_' + el.id.toString() + "_child");

      img.addEventListener('click', (e) => {
        this.imgChange(el.id);
      });
    }
    else {
      img.innerHTML = '<i class="fa fa-circle"></i>';
      img.style.fontSize = this.iconSize / 3 + "px";
    }

    col1.appendChild(img);

    rw.appendChild(col1);
    var col2 = document.createElement('div');    
    col2.style.display = "table-cell";
    col2.style.color = this.fontColor;
    col2.style.fontFamily = this.fontFamily;
    col2.style.cursor = "pointer";

    var elm = document.createElement('div');
    elm.classList.add('treeElement');
    elm.id = "id_theme_" + el.id.toString();
    elm.style.fontSize = this.fontSize + 'px';
    elm.style.display = "table-cell";
    elm.style.paddingBottom = "2px";
    elm.style.paddingLeft = "2px";
    elm.style.paddingRight = "10px";

    // elm.addEventListener('mouseenter', e => {
    //   elm.style.color = this.hoverColor;
    // });
    // elm.addEventListener('mouseleave', e => {
    //   if (elm.classList.contains('activeItem'))
    //     elm.style.color = 'white';
    //   else
    //     elm.style.color = this.fontColor;
    // });

    elm.innerText = el.name;

    elm.addEventListener('click', (e) => {
      var elms = document.getElementsByClassName('activeItem');
      if (elms)
        Array.prototype.forEach.call(elms, function (ell) {
          (<HTMLElement>ell).classList.remove('activeItem');
          // (<HTMLElement>ell).style.color = this.fontColor;
          // (<HTMLElement>ell).style.backgroundColor = 'transparent';
        });

      elm.classList.add('activeItem');
      // elm.style.backgroundColor = this.selectedColor;
      // elm.style.color = 'white';
      this.router.navigate([this.BookName, el.id]);
      let th = new Theme();
      th.id = el.id;
      th.name = elm.innerText;
      this.itemclick.emit(th);
    });

    col2.appendChild(elm);
    rw.appendChild(col2);
    if (themes0.length > 0) {
      // elm.setAttribute('data-toggle', 'collapse');
      // elm.setAttribute('data-target', '#id_theme_' + el.id.toString() + "_child");

      var div_child = document.createElement('div');
      div_child.id = "id_theme_" + el.id.toString() + "_child";
      div_child.className = "collapse";
      col2.appendChild(div_child);
      themes0.forEach(tt => {
        var el1 = this.loadRow(tt);
        div_child.appendChild(el1);
      });
    }
    return rw;
  }

  imgChange(id: number) {
    var imgid = "id_theme_" + id.toString() + "_img";
    var img = <HTMLElement>document.getElementById(imgid);
    var chid = "id_theme_" + id.toString() + "_child";
    var ch = <HTMLElement>document.getElementById(chid);

    if (img != null) {
      if (img.innerHTML.indexOf("right") >= 0) {
        img.innerHTML = '<i class="fa fa-caret-down"></i>';
        ch.className = 'expanded';
      }
      else if (img.innerHTML.indexOf("down") >= 0) {
        img.innerHTML = '<i class="fa fa-caret-right"></i>';
        ch.className = 'collapse';
      }
    }
  }
  expandElid(id: number) {
    var el = this.data.find(t => t.id == id);
    if (el != null && el.parentid > 0) {
      var par = document.getElementById("id_theme_" + el.parentid.toString() + "_child");
      if (par != null)
        par.className = "expanded";

      var img = <HTMLImageElement>document.getElementById("id_theme_" + el.parentid.toString() + "_img");
      if (img) {
        img.innerHTML = '<i class="fa fa-caret-down"></i>';
      }

      this.expandElid(el.parentid);
    }
  }
}
