import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule }   from '@angular/common/http';

import {MenuComponent} from './shared/menu/menu.component';
import {DatatreeComponent} from './shared/datatree/datatree.component';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BookComponent } from './book/book.component';
import { HtmlbookComponent } from './htmlbook/htmlbook.component';
import { JsbookComponent } from './jsbook/jsbook.component';
import { PhpbookComponent } from './phpbook/phpbook.component';
import { NodejsbookComponent } from './nodejsbook/nodejsbook.component';
import { JquerybookComponent } from './jquerybook/jquerybook.component';
import { PythonComponent } from './python/python.component';
import { ReactComponent } from './react/react.component';
import { ReclamComponent } from './reclam/reclam.component';

const routes: Routes = [
  { path: '', component: HomeComponent },  
  { path: 'html', component: HtmlbookComponent },  
  { path: 'html/:id', component: HtmlbookComponent },  
  { path: 'js', component: JsbookComponent },  
  { path: 'js/:id', component: JsbookComponent },  
  { path: 'php', component: PhpbookComponent },  
  { path: 'php/:id', component: PhpbookComponent },  
  { path: 'nodejs', component: NodejsbookComponent },  
  { path: 'nodejs/:id', component: NodejsbookComponent },  
  { path: 'jquery', component: JquerybookComponent },
  { path: 'jquery/:id', component: JquerybookComponent },
  { path: 'python', component: PythonComponent },
  { path: 'python/:id', component: PythonComponent },
  { path: 'react', component: ReactComponent },
  { path: 'react/:id', component: ReactComponent },
  { path: 'reclam', component: ReclamComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    DatatreeComponent,
    BookComponent,
    HtmlbookComponent,
    JsbookComponent,
    PhpbookComponent,
    NodejsbookComponent,
    JquerybookComponent,
    PythonComponent,
    ReactComponent,
    ReclamComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(routes),
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
