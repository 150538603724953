import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-phpbook',
  templateUrl: './phpbook.component.html',
  styleUrls: ['./phpbook.component.css']
})
export class PhpbookComponent implements OnInit {

  bookTitle="PHP სახელმძღვანელო";

  private routeSubscription: Subscription;
  BookId: number;

  constructor(private activateRoute: ActivatedRoute,private router:Router) {
    this.routeSubscription = activateRoute.params.subscribe(params => {
      this.BookId = params['id'];
    });
   }

  ngOnInit() {
  }

}
