import { Component, OnInit } from '@angular/core';
import { Theme, DataService, TEST_MODE } from '../sdk/dataservice';

declare const require: any;
var pako = require('pako');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DataService]
})
export class HomeComponent implements OnInit {

  constructor(private dataservice: DataService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    let div1 = document.getElementById('div1');
    let div2 = document.getElementById('div2');
    if (!div1 || !div2)
      return;

    if (TEST_MODE) {
      div1.innerHTML = `<h2><strong>საიტის შესახებ</strong></h2>

      <p><img src="images/nb.jpg" style="float:left; padding-bottom:20px; padding-right:20px; padding-top:10px; width:300px" /></p>
      
      <p style="text-align:justify"><span style="font-size:16px">ქართული ინტერნეტ სივრცე არც ისე მდიდარია ქართულენოვანი ლიტერატურით, რომელიც განკუთვნილია პროგრამირების შემსწავლელთათვის. წინამდებარე საიტი ერთგვარი მოკრძალებული მცდელობაა, შეძლებისდაგვარად შეავსოს ეს ხარვეზი. აქ წარმოდგენილი იქნება ორიგინალური თუ ნათარგმნი სახელმძღვანელოები და სტატიები, მიძღვნილი პროგრამირების სხვადასხვა ენებისადმი. იმედია, აქ წარმოდგენილი ლიტერატურა სასარგებლო იქნება როგორც დამწყები, ასევე გამოცდილი პროგრამისტებისათვის.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px">საიტის ძირითადი მიმართულება იქნება ვებ პროგრამირებისთვის განკუთვნილი პროგრამირების ენები და ფრეიმვორკები, პროგრამირება .net-ზე, მონაცემთა ბაზები და ა. შ.</span></p>`;
      div2.innerHTML = `<h2 style="text-align:justify"><strong>სიახლეები:</strong></h2>

      <p><span style="font-size:16px"><strong>11.02.2018:</strong>&nbsp;<a href="/nodejs">Node.js-ის სახელმძღვანელოს</a>&nbsp;დაემატა <a href="/nodejs?id=1623">მე-5&nbsp;თავი: ტესტირება</a>.</span></p>
      
      <p><span style="font-size:16px"><strong>28.01.2018:</strong>&nbsp;<a href="/nodejs">Node.js-ის სახელმძღვანელოს</a>&nbsp;დაემატა <a href="/nodejs?id=1603">მე-4 თავი:&nbsp;Express</a>.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>10.01.2018:</strong>&nbsp;<a href="/nodejs">Node.js-ის სახელმძღვანელოს</a>&nbsp;დაემატა <a href="/nodejs?id=1598">მე-3&nbsp;თავი:&nbsp;სერვერი</a>.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>07.01.2018:</strong>&nbsp;<a href="/nodejs">Node.js-ის სახელმძღვანელოს</a>&nbsp;დაემატა <a href="/nodejs?id=1585">მე-2 თავი:&nbsp;Node.js-თან მუშაობის საფუძვლები</a>.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>02.01.2018:</strong>&nbsp;დაიწყო მუშაობა <a href="/nodejs">Node.js-ის სახელმძღვანელოს</a> თარგმნაზე. გამოქვეყნდა პირველი თავი, რომელშიც განხილულია Node.js-ის საფუძვლები.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>26.12.2017:</strong>&nbsp;<a href="/php7">PHP-ის სახელმძღვანელოს</a> დაემატა ბოლო <a href="/php7?id=1568">მე-7 თავი:&nbsp;მუშაობა მონაცემთა ბაზებთან. MySQL.</a></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>24.12.2017:</strong>&nbsp;<a href="/php7">PHP-ის სახელმძღვანელოს</a> დაემატა <a href="/php7?id=1562">მე-6 თავი:&nbsp;ობიექტზე ორიენტირებული პროგრამირება</a>.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>20.12.2017:</strong>&nbsp;<a href="/php7">PHP-ის სახელმძღვანელოს</a> დაემატა <a href="/php7?id=1553">მე-5&nbsp;თავი: ფაილებთან მუშაობა</a>.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>17.12.2017:</strong>&nbsp;<a href="/php7">PHP-ის სახელმძღვანელოს</a> დაემატა<a href="/php7?id=1546"> მე-4 თავი: PHP-ის საბაზისო შესაძლებლობები</a>.</span></p>
      `;
      return;
    }

    this.dataservice.getText(1484).subscribe(res => {
      if (res.status == 200) {
        div1.innerHTML = this.ungzip(res.text());
        // var txt = res.text();
        // const buffer = Buffer.from(txt, 'base64');
        // zlib.unzip(buffer, (err, buffer) => {
        //   if (!err) {
        //     console.log(buffer.toString());
        //     div1.innerHTML = buffer.toString();
        //   } else {
        //     div1.innerHTML = "";
        //   }
        //   });
      }
    });
    this.dataservice.getText(1485).subscribe(res => {
      if (res.status == 200) {
        var txt = res.text();
        div2.innerHTML = this.ungzip(res.text());
        //     const buffer = Buffer.from(txt, 'base64');
        //     zlib.unzip(buffer, (err, buffer) => {
        //       if (!err) {
        //         console.log(buffer.toString());
        //         div2.innerHTML = buffer.toString();
        //       } else {
        //         div2.innerHTML = "";
        //       }
        //     });
      }
    });
  }
  ungzip(source: string) {
    return pako.inflate(atob(source), { to: 'string' });
  }
}
