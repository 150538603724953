import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-jsbook',
  templateUrl: './jsbook.component.html',
  styleUrls: ['./jsbook.component.css']
})
export class JsbookComponent implements OnInit {
  bookTitle="JAVASCRIPT-ის სახელმძღვანელო";
  
  private routeSubscription: Subscription;
  BookId: number;

  constructor(private activateRoute: ActivatedRoute,private router:Router) {
    this.routeSubscription = activateRoute.params.subscribe(params => {
      this.BookId = params['id'];
    });
   }

  ngOnInit() {

  }

}
