import { Component, OnInit } from '@angular/core';
import { DataService } from '../sdk/dataservice';

declare const require: any;
var pako = require('pako');

@Component({
  selector: 'app-reclam',
  templateUrl: './reclam.component.html',
  styleUrls: ['./reclam.component.css'],
  providers: [DataService]
})
export class ReclamComponent implements OnInit {

  constructor(private dataservice: DataService) { }

  ngOnInit() {
    this.getInfo();
  }
  getInfo() {
    this.dataservice.getText(2935).subscribe(res => {
      if (res.status == 200) {
        var txt = res.text();
        let div2=document.getElementById("reclam");
        div2.innerHTML = this.ungzip(res.text());
        //     const buffer = Buffer.from(txt, 'base64');
        //     zlib.unzip(buffer, (err, buffer) => {
        //       if (!err) {
        //         console.log(buffer.toString());
        //         div2.innerHTML = buffer.toString();
        //       } else {
        //         div2.innerHTML = "";
        //       }
        //     });
      }
    });
  }
  ungzip(source: string) {
    return pako.inflate(atob(source), { to: 'string' });
  }
}
