import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Theme, DataService, TEST_MODE } from '../sdk/dataservice';
import { DatatreeComponent } from '../shared/datatree/datatree.component';
import { Router, ActivatedRoute } from '@angular/router';

declare const require: any;
var pako = require('pako');

// const zlib = require('zlib');

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css'],
  providers: [DataService, DatatreeComponent]
})
export class BookComponent implements OnInit, AfterViewInit {

  @ViewChild(DatatreeComponent) myTree: DatatreeComponent;
  @Input() MyThemes: Theme[];
  @Input() BookName: string;
  @Input() BookTitle: string;
  @Input() BookId: number;
  SortedList: Theme[];
  ThemeTitle: string;
  loading: boolean = false;

  constructor(private dataservice: DataService, private datatree: DatatreeComponent,
    private router: Router) {

  }
  createSortedList() {
    let list = this.MyThemes.filter(el => !(el.parentid > 0));
    list.forEach(el => this.addToSorted(this.SortedList, el));
  }
  addToSorted(list: Theme[], el: Theme) {
    list.push(el);
    let childs = this.MyThemes.filter(e => e.parentid == el.id);
    if (childs.length > 0) {
      childs.forEach(x => {
        this.addToSorted(list, x);
      });
    }
  }
  ngOnInit() {
    this.loading = true;
    this.menuItemClick(this.BookName);
    if (this.BookId)
      this.dataTreeItemclick(this.BookId);
  }
  ngAfterViewInit() {
    this.dataservice.getData(this.BookName).subscribe(res => {
      if (res.status == 200) {
        var json = res.text(), obj = JSON && JSON.parse(json);
        if (obj.length > 0) {
          let data = new Array<Theme>();
          obj.forEach(element => {
            var theme = new (Theme);
            theme.id = element[0];
            theme.name = element[1];
            theme.parentid = element[2];
            data.push(theme);
          });
          this.MyThemes = data;
          this.myTree.reInit(this.MyThemes, this.BookId);
        }
        this.loading = false;
      }
      // else if (TEST_MODE) {
      //   setTimeout(() => {
      //     this.MyThemes = res as Theme[];
      //     this.myTree.reInit(this.MyThemes, this.BookId);
      //     this.loading = false;
      //   }, 1000);
      // }
      else
        this.loading = false;
    });
  }
  dataTreeItemclick($event) {
    this.ThemeTitle = $event.name;
    let txtArea = document.getElementById("idText");
    if (txtArea) {
      let th = <Theme>$event;
      this.loading = true;
      this.dataservice.getText(th.id).subscribe(res => {
        // if (TEST_MODE) {
        //   setTimeout(() => {
        //     txtArea.innerHTML = res;
        //     this.loading = false;
        //   }, 1000);
        // }
        // else 
        if (res.status == 200) {
          // var txt = res.text();
          // const buffer = Buffer.from(txt, 'base64');
          // zlib.unzip(buffer, (err, buffer) => {
          //   if (!err) {
          //     console.log(buffer.toString());
          //     txtArea.innerHTML = buffer.toString();
          //   } else {
          //     txtArea.innerHTML = "";
          //   }
          // });
          txtArea.innerHTML = this.ungzip(res.text());
          this.loading = false;
        }
        else
          this.loading = false;
      });
    }
    this.reloadReclam();
  }

  menuItemClick(bname: string) {
    this.clearActive();
    this.activateMenu(bname);
  }
  clearActive() {
    let menuItems = document.getElementsByClassName("menuitem");
    Array.prototype.forEach.call(menuItems, function (el) {
      (<HTMLElement>el).classList.remove('active');
    });
  }
  activateMenu(bname: string) {
    var el = document.getElementById(bname);
    if (el != null) {
      el.classList.add('active');
    }
  }
  ungzip(source: string) {
    return pako.inflate(atob(source), { to: 'string' });
  }
  reloadReclam() {
    let frame: any = document.getElementById('reclam');
    frame.contentWindow.location.reload();
  }
  gotoNext() {
    let next: Theme;
    let curid = this.BookId;
    if (!this.SortedList || this.SortedList.length == 0) {
      this.SortedList = [];
      this.createSortedList();
    }
    let cur = this.SortedList.filter(e => e.id == curid)[0];
    let curindex = this.SortedList.indexOf(cur);
    if (curindex < this.SortedList.length - 1) {
      next = this.SortedList[curindex + 1];
    }
    if (next) {
      let elid = 'id_theme_' + next.id.toString();
      let nextel = document.getElementById(elid);
      if (next.parentid > 0) {
        let parel = document.getElementById('id_theme_' + next.parentid.toString() + '_child');
        if (parel) {
          parel.classList.remove('collapse');
          parel.classList.add('expanded');
        }
      }
      nextel.click();
      window.scrollTo({ top: 0 });
    }
  }
  gotoPrev() {
    let prev: Theme;
    let curid = this.BookId;
    if (!this.SortedList || this.SortedList.length == 0) {
      this.SortedList = [];
      this.createSortedList();
    }
    let cur = this.SortedList.filter(e => e.id == curid)[0];
    let curindex = this.SortedList.indexOf(cur);
    if (curindex > 0) {
      prev = this.SortedList[curindex - 1];
    }
    if (prev) {
      let elid = 'id_theme_' + prev.id.toString();
      let nextel = document.getElementById(elid);
      if (prev.parentid > 0) {
        let parel = document.getElementById('id_theme_' + prev.parentid.toString() + '_child');
        if (parel) {
          parel.classList.remove('collapse');
          parel.classList.add('expanded');
        }
      }
      nextel.click();
      window.scrollTo({ top: 0 });
    }
  }
}
