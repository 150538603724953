import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/Rx';
import 'rxjs/add/observable/of';

// const zlib = require('zlib');
export const TEST_MODE = false;

export class Theme {
    id: number;
    name: string;
    parentid: number;
    security: number;
}

@Injectable()
export class DataService {
    constructor(private http: Http, private httpClient: HttpClient) {
    }

    public getText(id) {
        // if (!id)
        //     return Observable.of("");         
        // if (TEST_MODE)
        //         return Observable.of(id.toString());

        var url = "phpscript/getpublictext.php?id=" + id;
        if (TEST_MODE)
            url = "http://localhost:8080/" + url;
        return this.http.get(url);
        // .map((response: any) => {
        //     if (response.Result) {
        //         return response.Result;
        //     }
        //     return response;
        // });
    }
    public getData(bname: string) {
        // if (TEST_MODE)     
        //         return this.getDataTest(bname);

        let url = "phpscript/getpublicthemes.php?nic=" + bname;
        if (TEST_MODE)
            url = "http://localhost:8080/" + url;
        return this.http.get(url);
        // .map((response: any) => {
        //     if (response.Result) {
        //         return response.Result;
        //     }
        //     return response;
        // });
    }
    getDataTest(bname: string) {
        let data: Theme[] =
            [
                { id: 1, name: bname + " - ერთი", parentid: null, security: 0 },
                { id: 2, name: bname + " - ორი", parentid: null, security: 0 },
                { id: 3, name: bname + " - სამი", parentid: null, security: 0 },
                { id: 4, name: bname + " - ოთხი", parentid: null, security: 0 },
                { id: 5, name: bname + " - ხუთი", parentid: null, security: 0 },
                { id: 6, name: bname + " - ნახეს უცხო მოყმე ვინმე, ჯდა მტირალი წყლისა პირსა, შავი ცხენი სადავითა, ჰყვა ლომსა და ვითა გმირსა...", parentid: 1, security: 0 },
                { id: 7, name: bname + " - შვიდი", parentid: 2, security: 0 },
                { id: 8, name: bname + " - რვა", parentid: 3, security: 0 },
                { id: 9, name: "ცხრა", parentid: 1, security: 0 },
                { id: 10, name: "ათი", parentid: 3, security: 0 },
                { id: 11, name: "თერთმეტი", parentid: 4, security: 0 },
                { id: 12, name: "თორმეტი", parentid: 4, security: 0 },
                { id: 13, name: "ცამეტი", parentid: 4, security: 0 },
                { id: 14, name: "თოთხმეტი", parentid: 7, security: 0 },
                { id: 15, name: "თხუთმეტი", parentid: 7, security: 0 },
                { id: 16, name: "თექვსმეტი", parentid: 7, security: 0 },
                { id: 17, name: "ჩვიდმეტი", parentid: 10, security: 0 },
                { id: 18, name: "თვრამეტი", parentid: 10, security: 0 },
                { id: 19, name: "ცხრამეტი", parentid: 8, security: 0 },
                { id: 20, name: "ოცი", parentid: 7, security: 0 },
                { id: 21, name: "ოცდაერთი", parentid: 17, security: 0 },
                { id: 22, name: "ოცდაერთი22", parentid: 10, security: 0 },
                { id: 23, name: "ოცდაერთი23", parentid: 11, security: 0 },
                { id: 24, name: "ოცდაერთი24", parentid: 21, security: 0 },
                { id: 25, name: "ოცდაერთი25", parentid: 20, security: 0 },
                { id: 26, name: "ოცდაერთი26", parentid: 21, security: 0 },
                { id: 27, name: "ოცდაერთი27", parentid: 11, security: 0 },
                { id: 28, name: "ოცდაერთი28", parentid: 15, security: 0 },
                { id: 29, name: "ოცდაერთი29", parentid: 10, security: 0 },
                { id: 30, name: "ოცდაერთი30", parentid: 11, security: 0 },
                { id: 31, name: "ოცდაერთი31", parentid: 31, security: 0 },
                { id: 32, name: "ოცდაერთი32", parentid: 7, security: 0 },
                { id: 33, name: "ოცდაერთი33", parentid: 32, security: 0 },
                { id: 34, name: "ოცდაერთი34", parentid: 32, security: 0 },
                { id: 35, name: "ოცდაერთი35", parentid: 33, security: 0 },
                { id: 36, name: "ოცდაერთი36", parentid: null, security: 0 },
                { id: 37, name: "ოცდაერთი37", parentid: 39, security: 0 },
                { id: 38, name: "ოცდაერთი38", parentid: 35, security: 0 },
                { id: 39, name: "ოცდაერთი39", parentid: null, security: 0 },
                { id: 40, name: "ოცდაერთი40", parentid: null, security: 0 },
                { id: 41, name: "ოცდაერთი41", parentid: null, security: 0 },
                { id: 42, name: "ოცდაერთი42", parentid: 37, security: 0 }
            ];
        // return Observable.of(data);
    }
}